import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { usePrayerStore } from '../../store/prayerStore';

interface PrayerFormData {
  name: string;
  request: string;
}

const PrayerForm: React.FC = () => {
  const [state, handleSubmit] = useForm("xldewapn");
  const [formData, setFormData] = useState<PrayerFormData>({
    name: '',
    request: ''
  });
  const { addRequest } = usePrayerStore();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.request.trim()) {
      toast.error('Please enter your prayer request');
      return;
    }
    
    try {
      const formSubmitData = new FormData();
      formSubmitData.append('name', formData.name);
      formSubmitData.append('prayer-request', formData.request);
      
      await handleSubmit(e);
      if (state.succeeded) {
        addRequest(formData.request, formData.name);
        toast.success('Prayer request submitted successfully');
        setFormData({ name: '', request: '' });
      }
    } catch (error) {
      toast.error('Failed to submit prayer request');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-lg shadow-md p-6"
    >
      <form onSubmit={onSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-secondary-light mb-2">
            Your Name (Optional)
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
            className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            placeholder="Enter your name"
          />
        </div>
        <div>
          <label htmlFor="prayer-request" className="block text-secondary-light mb-2">
            Share Your Prayer Request
          </label>
          <textarea
            id="prayer-request"
            name="prayer-request"
            value={formData.request}
            onChange={(e) => setFormData(prev => ({ ...prev, request: e.target.value }))}
            className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            rows={6}
            placeholder="Type your prayer request here..."
            required
          />
        </div>
        <button
          type="submit"
          disabled={state.submitting}
          className="w-full bg-primary text-secondary font-semibold py-3 rounded-md hover:bg-primary-dark transition-colors disabled:opacity-50"
        >
          {state.submitting ? 'Submitting...' : 'Submit Prayer Request'}
        </button>
      </form>
    </motion.div>
  );
};

export default PrayerForm;