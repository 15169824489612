import React from 'react';
import PrayerForm from '../components/prayer/PrayerForm';

const Prayer: React.FC = () => {
  return (
    <div className="max-w-md mx-auto">
      <PrayerForm />
    </div>
  );
};

export default Prayer;