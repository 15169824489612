import React from 'react';
import { motion } from 'framer-motion';
import { FaPlay, FaPause } from 'react-icons/fa';

interface PlayButtonProps {
  isPlaying: boolean;
  onClick: () => void;
  size?: number;
}

const PlayButton: React.FC<PlayButtonProps> = ({ isPlaying, onClick, size = 24 }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="text-primary hover:text-primary-light transition-colors"
      aria-label={isPlaying ? 'Pause' : 'Play'}
    >
      {isPlaying ? <FaPause size={size} /> : <FaPlay size={size} />}
    </motion.button>
  );
};

export default PlayButton;