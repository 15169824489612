import React from 'react';
import { motion } from 'framer-motion';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';

interface VolumeButtonProps {
  isMuted: boolean;
  onClick: () => void;
  size?: number;
}

const VolumeButton: React.FC<VolumeButtonProps> = ({ isMuted, onClick, size = 18 }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={onClick}
      className="text-primary hover:text-primary-light transition-colors"
      aria-label={isMuted ? 'Unmute' : 'Mute'}
    >
      {isMuted ? <FaVolumeMute size={size} /> : <FaVolumeUp size={size} />}
    </motion.button>
  );
};

export default VolumeButton;