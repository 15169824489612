import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { motion } from 'framer-motion';
import ThemeToggle from './ThemeToggle';

const Header: React.FC = () => {
  return (
    <header className="bg-secondary dark:bg-gray-800 text-white py-4 shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2">
            <motion.h1 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="text-2xl md:text-3xl font-bold tracking-tight"
            >
              <span className="text-primary">Praise</span>
              <span className="text-white">Radio</span>
              <span className="text-primary">NG</span>
            </motion.h1>
          </Link>
          
          <nav className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-white hover:text-primary transition-colors">Home</Link>
            <Link to="/programs" className="text-white hover:text-primary transition-colors">Programs</Link>
            <Link to="/prayer" className="text-white hover:text-primary transition-colors">Prayer</Link>
            <Link to="/support" className="text-white hover:text-primary transition-colors">Support Us</Link>
          </nav>
          
          <div className="flex items-center space-x-4">
            <ThemeToggle />
            <motion.a 
              whileHover={{ scale: 1.1 }}
              href="https://www.facebook.com/praiseradio2018"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-primary transition-colors"
            >
              <FaFacebook size={20} />
            </motion.a>
            <motion.a 
              whileHover={{ scale: 1.1 }}
              href="https://instagram.com/praiseradiong"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-primary transition-colors"
            >
              <FaInstagram size={20} />
            </motion.a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;