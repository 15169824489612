import React from 'react';
import { motion } from 'framer-motion';

const LiveIndicator: React.FC = () => {
  return (
    <motion.div 
      animate={{ scale: [1, 1.2, 1] }}
      transition={{ duration: 1.5, repeat: Infinity }}
      className="h-3 w-3 rounded-full bg-primary"
    />
  );
};

export default LiveIndicator;