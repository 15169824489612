import React from 'react';
import { useAudio } from '../contexts/AudioContext';
import { usePlayerStore } from '../store/playerStore';

const GlobalAudioPlayer: React.FC = () => {
  const { audioRef } = useAudio();
  const { streamUrl } = usePlayerStore();

  return (
    <audio
      ref={audioRef}
      src={streamUrl}
      preload="auto"
      style={{ display: 'none' }}
    />
  );
};

export default GlobalAudioPlayer;