import React from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { PrayerRequest } from '../../types/prayer';

interface PrayerRequestItemProps {
  request: PrayerRequest;
}

const PrayerRequestItem: React.FC<PrayerRequestItemProps> = ({ request }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ duration: 0.3 }}
      className="p-4 bg-gray-50 rounded-lg border border-gray-100 hover:border-primary transition-colors"
    >
      <p className="text-secondary mb-2">{request.request}</p>
      <div className="flex justify-between items-center text-xs text-secondary-light">
        <span>{request.name ? `From: ${request.name}` : 'Anonymous'}</span>
        <span>{format(new Date(request.timestamp), 'MMM d, yyyy h:mm a')}</span>
      </div>
    </motion.div>
  );
};

export default PrayerRequestItem;