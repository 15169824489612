import React from 'react';
import { FaShare } from 'react-icons/fa';
import { motion } from 'framer-motion';

const ShareButton: React.FC = () => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'PraiseRadioNG',
        text: 'Listen to PraiseRadioNG - Your #1 Gospel Radio Station',
        url: window.location.href,
      });
    }
  };

  return (
    <motion.button 
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleShare}
      className="flex items-center space-x-2 px-4 py-2 rounded-full bg-secondary text-white"
    >
      <FaShare />
      <span>Share</span>
    </motion.button>
  );
};

export default ShareButton;