import React, { useState } from 'react';
import { FaHeart } from 'react-icons/fa';
import { motion } from 'framer-motion';

const LikeButton: React.FC = () => {
  const [isLiked, setIsLiked] = useState(false);

  return (
    <motion.button 
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsLiked(!isLiked)}
      className={`flex items-center space-x-2 px-4 py-2 rounded-full ${
        isLiked ? 'bg-primary text-white' : 'bg-gray-100 text-gray-600'
      }`}
    >
      <FaHeart />
      <span>{isLiked ? 'Loved' : 'Love'}</span>
    </motion.button>
  );
};

export default LikeButton;