import React from 'react';
import { motion } from 'framer-motion';
import { usePlayerStore } from '../../../store/playerStore';

interface SongInfoProps {
  maxWidth?: string;
}

const SongInfo: React.FC<SongInfoProps> = ({ maxWidth = "200px" }) => {
  const { currentSong } = usePlayerStore();

  return (
    <div className={`text-white overflow-hidden`} style={{ maxWidth }}>
      <motion.div
        initial={{ x: "100%" }}
        animate={{ x: "-100%" }}
        transition={{
          duration: 15,
          repeat: Infinity,
          ease: "linear"
        }}
        className="whitespace-nowrap"
      >
        <span className="text-sm">{currentSong}</span>
      </motion.div>
    </div>
  );
};

export default SongInfo;