import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { PrayerRequest, PrayerState } from '../types/prayer';
import { formatPrayerRequest, handleStorageEvent, triggerStorageUpdate } from '../utils/prayer';

export const usePrayerStore = create<PrayerState>()(
  persist(
    (set, get) => ({
      requests: [],
      addRequest: (request: string, name?: string) => {
        const newRequest = formatPrayerRequest(request, name);
        const updatedRequests = [newRequest, ...get().requests].slice(0, 10);
        set({ requests: updatedRequests });
        triggerStorageUpdate(updatedRequests);
      },
      clearRequests: () => set({ requests: [] }),
      subscribeToUpdates: () => {
        const updateCallback = (requests: PrayerRequest[]) => set({ requests });
        const listener = (e: StorageEvent) => handleStorageEvent(e, updateCallback);
        window.addEventListener('storage', listener);
      },
      unsubscribeFromUpdates: () => {
        const updateCallback = (requests: PrayerRequest[]) => set({ requests });
        const listener = (e: StorageEvent) => handleStorageEvent(e, updateCallback);
        window.removeEventListener('storage', listener);
      },
    }),
    {
      name: 'prayer-storage',
      version: 1,
    }
  )
);