export const handleAudioPlay = async (audio: HTMLAudioElement): Promise<void> => {
  try {
    if (audio.paused) {
      await audio.play();
    }
  } catch (error) {
    console.error('Error playing audio:', error);
    throw error;
  }
};

export const handleAudioPause = (audio: HTMLAudioElement): void => {
  if (!audio.paused) {
    audio.pause();
  }
};

export const updateAudioVolume = (
  audio: HTMLAudioElement,
  volume: number,
  isMuted: boolean
): void => {
  audio.volume = isMuted ? 0 : volume;
  audio.muted = isMuted;
};