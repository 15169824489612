import { PrayerRequest } from '../types/prayer';

export const formatPrayerRequest = (request: string, name?: string): PrayerRequest => {
  return {
    id: crypto.randomUUID(),
    request,
    name,
    timestamp: new Date().toISOString(),
  };
};

export const handleStorageEvent = (
  e: StorageEvent,
  callback: (requests: PrayerRequest[]) => void
) => {
  if (e.key === 'prayer-storage' && e.newValue) {
    const newState = JSON.parse(e.newValue);
    callback(newState.state.requests);
  }
};

export const triggerStorageUpdate = (requests: PrayerRequest[]) => {
  const event = new StorageEvent('storage', {
    key: 'prayer-storage',
    newValue: JSON.stringify({
      state: { requests },
      version: 1,
    }),
  });
  window.dispatchEvent(event);
};