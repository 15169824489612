import React from 'react';
import PlayButton from './PlayButton';
import VolumeButton from './VolumeButton';

interface ControlsProps {
  variant: 'mini' | 'full';
  isPlaying: boolean;
  isMuted: boolean;
  onPlayPause: () => void;
  onMuteToggle: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  variant,
  isPlaying,
  isMuted,
  onPlayPause,
  onMuteToggle
}) => {
  return (
    <div className="flex items-center space-x-4">
      <PlayButton 
        isPlaying={isPlaying} 
        onClick={onPlayPause}
        size={variant === 'mini' ? 24 : 32} 
      />
      <VolumeButton 
        isMuted={isMuted}
        onClick={onMuteToggle}
        size={variant === 'mini' ? 18 : 24}
      />
    </div>
  );
};

export default Controls;