import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PlayerState {
  isPlaying: boolean;
  isMuted: boolean;
  volume: number;
  currentTime: number;
  currentSong: string;
  streamUrl: string;
  setPlaying: (playing: boolean) => void;
  setMuted: (muted: boolean) => void;
  setVolume: (volume: number) => void;
  setCurrentTime: (time: number) => void;
  setCurrentSong: (song: string) => void;
}

export const usePlayerStore = create<PlayerState>()(
  persist(
    (set) => ({
      isPlaying: false,
      isMuted: false,
      volume: 1,
      currentTime: 0,
      currentSong: 'PraiseRadioNG Live Worship',
      streamUrl: 'https://stream.zeno.fm/9xwv2tuzoqsuv',
      setPlaying: (playing) => set({ isPlaying: playing }),
      setMuted: (muted) => set({ isMuted: muted }),
      setVolume: (volume) => set({ volume }),
      setCurrentTime: (time) => set({ currentTime: time }),
      setCurrentSong: (song) => set({ currentSong: song }),
    }),
    {
      name: 'player-storage',
    }
  )
);