import { useEffect } from 'react';
import { usePlayerStore } from '../store/playerStore';
import { handleAudioPlay, handleAudioPause, updateAudioVolume } from '../utils/audio';

export const useAudioSync = (audioRef: React.RefObject<HTMLAudioElement>) => {
  const { isPlaying, isMuted, volume, streamUrl, setPlaying } = usePlayerStore();

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    // Set initial source
    if (audio.src !== streamUrl) {
      audio.src = streamUrl;
    }

    // Update play state
    if (isPlaying) {
      handleAudioPlay(audio).catch(() => setPlaying(false));
    } else {
      handleAudioPause(audio);
    }

    // Update volume and mute state
    updateAudioVolume(audio, volume, isMuted);

    return () => {
      if (!audio.paused) {
        audio.pause();
      }
    };
  }, [isPlaying, isMuted, volume, streamUrl, setPlaying]);
};