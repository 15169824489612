import React from 'react';
import { FaHeart, FaShare, FaHandHoldingHeart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AudioPlayer from './audio/AudioPlayer';
import { usePlayerStore } from '../store/playerStore';
import ShareButton from './common/ShareButton';
import LikeButton from './common/LikeButton';

const RadioPlayer: React.FC = () => {
  const { currentSong } = usePlayerStore();
  const [listeners] = React.useState(Math.floor(Math.random() * 1000) + 500);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="w-full max-w-3xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden"
    >
      <div className="bg-gradient-to-r from-secondary to-secondary-dark p-6 text-white">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <motion.div 
              animate={{ scale: [1, 1.2, 1] }}
              transition={{ duration: 1.5, repeat: Infinity }}
              className="h-3 w-3 rounded-full bg-primary"
            />
            <span className="font-medium">LIVE</span>
          </div>
          <div className="flex items-center space-x-2">
            <FaHandHoldingHeart className="text-primary" />
            <span>{listeners} listening</span>
          </div>
        </div>
        
        <h2 className="text-2xl font-bold mb-2">Now Playing</h2>
        <p className="text-primary-light">{currentSong}</p>
      </div>
      
      <div className="p-4">
        <AudioPlayer variant="full" />
        
        <div className="flex justify-center space-x-4 mt-4">
          <LikeButton />
          <ShareButton />
          <Link 
            to="/support"
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-primary text-white"
          >
            <FaHandHoldingHeart />
            <span>Donate</span>
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default RadioPlayer;