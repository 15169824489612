import React from 'react';
import { motion } from 'framer-motion';
import AudioPlayer from './audio/AudioPlayer';

const MiniPlayer: React.FC = () => {
  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className="fixed bottom-0 left-0 right-0 z-50 h-12 bg-secondary dark:bg-gray-800 shadow-lg transition-all duration-300"
    >
      <div className="container mx-auto h-full">
        <AudioPlayer variant="mini" />
      </div>
    </motion.div>
  );
};

export default MiniPlayer;