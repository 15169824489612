import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaPray } from 'react-icons/fa';
import PrayerRequestItem from './prayer/PrayerRequestItem';
import { usePrayerRequests } from '../hooks/usePrayerRequests';

const PrayerRequests: React.FC = () => {
  const { requests } = usePrayerRequests();

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-white rounded-lg shadow-md p-6"
    >
      <div className="flex items-center space-x-2 mb-6">
        <FaPray className="text-primary" size={20} />
        <h3 className="text-xl font-semibold text-secondary">Recent Prayer Requests</h3>
      </div>
      
      <div className="space-y-4 max-h-[400px] overflow-y-auto">
        <AnimatePresence mode="popLayout">
          {requests.length === 0 ? (
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-secondary-light text-center py-4"
            >
              No prayer requests yet. Be the first to submit one.
            </motion.p>
          ) : (
            requests.map((request) => (
              <PrayerRequestItem key={request.id} request={request} />
            ))
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default PrayerRequests;