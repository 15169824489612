import React from 'react';
import { usePlayerStore } from '../../store/playerStore';
import { AudioControls } from './controls';
import { AudioDisplay } from './display';

interface AudioPlayerProps {
  variant?: 'mini' | 'full';
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ variant = 'mini' }) => {
  const { isPlaying, isMuted, setPlaying, setMuted } = usePlayerStore();

  return (
    <div className="flex items-center justify-between w-full px-4 h-full">
      <AudioDisplay variant={variant} />
      <AudioControls 
        variant={variant}
        isPlaying={isPlaying}
        isMuted={isMuted}
        onPlayPause={() => setPlaying(!isPlaying)}
        onMuteToggle={() => setMuted(!isMuted)}
      />
    </div>
  );
};

export default AudioPlayer;