import React from 'react';
import SongInfo from './SongInfo';
import LiveIndicator from './LiveIndicator';

interface DisplayProps {
  variant: 'mini' | 'full';
}

const Display: React.FC<DisplayProps> = ({ variant }) => {
  return (
    <div className="flex items-center space-x-4">
      <LiveIndicator />
      <SongInfo maxWidth={variant === 'mini' ? "300px" : "400px"} />
    </div>
  );
};

export default Display;