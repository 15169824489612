import { useEffect } from 'react';
import { usePrayerStore } from '../store/prayerStore';

export const usePrayerRequests = () => {
  const { requests, subscribeToUpdates, unsubscribeFromUpdates } = usePrayerStore();

  useEffect(() => {
    subscribeToUpdates();
    return () => unsubscribeFromUpdates();
  }, [subscribeToUpdates, unsubscribeFromUpdates]);

  return { requests };
};